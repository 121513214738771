@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal
}



@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal
}
* {
    margin: 0;
    padding: 0;
    font-family: "Gotham", sans-serif;
    font-weight: 400;
    /* font-family: 'Gotham2', sans-serif; */
    box-sizing: border-box;
    outline: none;
}

h1 {
    font-size: 19px;
    margin-top: 0px auto;
    text-transform: uppercase;
}

.logo {
    width: calc(100% - 29px);
    margin: 25px auto;
}
.header {
    width: calc(100% - 29px);
    height: 49px;
    background-color: #ea3323;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
    margin: 21px auto 25px;
    overflow: hidden;
}
.layout-wrapper {
    width: 100%;
    height: calc(100vh - 67px);
}

.status-wrapper {
    width: calc(100% - 29px);
    margin: 0 auto;
    /* margin-bottom: 10px; */
    display: flex;
    flex-direction: column;
    /* gap: 18px; */
    padding-top: 10px;

table {
    margin-top: 0 auto;
}
table th,
table {
  border: 2px solid #cdc6c629;
  padding: 8px;
  border-radius: 8px;
}

table th {
  background-color: #cdc6c629;
}

td {
    width: 80%;
}

}
.status-wrapper-info-block {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  border: 2px solid #6d6d6d;
  display: flex;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
  align-items: start;
  padding: 0 43px;
}

.span-info {
  font-size: 16px;
}
.span-text {
  font-size: 12px;
}

.order-number-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 11px;
    text-align: center;
    width: 100%;
    height: 80px;
    font-size: 64px;
    color: #000000;
  }
  
  .digit-block {
    border-radius: 8px;
    border: 2px solid #000000;
    line-height: 80px;
    font-weight: 700;
  }
  /* .digit-block-status_ready {
    border-radius: 8px;
    border: 2px solid #ea3323;
    line-height: 80px;
    font-weight: 700;
    color: white;
    background-color: red;
  }
  .digit-block-status_process {
    border-radius: 8px;
    border: 2px solid #23ea44;
    line-height: 80px;
    font-weight: 700;
    color: white;
    background-color: #23ea44;
  } */


.text-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
  text-align: center;
  width: 100%;
  height: 58px;
  font-size: 26px;
  }


  .digit-block-status_ready {
    border-radius: 3px;
    /* border: 2px solid #23ea44; */
    line-height: 58px;
    font-weight: 700;
    color: white;
    background-color: #73c24d;
    /* animation: slideUp 0.5s ease-in-out forwards; */
  }
  
 
  
  /* @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } */

  .digit-block-status_process {
    border-radius: 3px;
    /* border: 2px solid #23ea44; */
    line-height: 58px;
    font-weight: 700;
    color: white;
    background-color: #c2c2c2;
  }
  
  .digit-block-status_done {
    border-radius: 3px;
    /* border: 2px solid #23ea44; */
    line-height: 58px;
    font-weight: 700;
    color: white;
    background-color: #000000;
  }