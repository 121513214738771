body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* overflow-x: hidden;  */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* overflow-x: hidden; */
}

/* h1 {
  text-align: center;
} */



.nav-bar {
  width: 100%;
  background-color: #f4f4f4;
  /* position: fixed; */
  position: -webkit-sticky;
  position: sticky;

  top: 0;
  z-index: 1000;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: auto; /* Добавляет горизонтальную прокрутку, если элементы не помещаются */
}

.nav-bar ul {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.nav-bar li {
  display: inline-block;
  padding: 10px;
}

.nav-bar button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.nav-bar button.active {
  background-color: #ddd;

}

.menu-items {
  width: 100%;
  margin-top: 60px; 
  overflow-x: hidden; 
}

.category-section {
  margin-bottom: 40px;
}

.category-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.item-grid {
  display: flex;
  flex-wrap: wrap; /* Элементы будут переноситься на новую строку, если не помещаются */
  gap: 20px;
}

.menu-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 200px;
  padding: 10px;
  /* flex: 1 1 calc(25% - 20px); */
  box-sizing: border-box;
}

.item-image-container img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.menu-item h3 {
  font-size: 18px;
  margin: 10px 0;
}

.menu-item p {
  font-size: 14px;
  margin: 5px 0;
}

.nutritions {
  margin-top: 10px;
}

/* Медиа-запрос для больших экранов (например, ПК) */
/* @media (min-width: 768px) {
  .menu-item {
    flex: 1 1 calc(25% - 20px); 
    display: grid;
grid-template-columns: 1fr 1fr;
  }
} */
@media (max-width: 768px) {
  .item-grid {

    display: grid;
grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .item-grid {

    display: grid;
grid-template-columns: 1fr 1fr 1fr;
  }
}